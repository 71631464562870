<template>
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6094 1L18.6811 7.07176"
      stroke="#7D4CFF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M12.6094 13.0898L18.6811 7.01808"
      stroke="#7D4CFF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M1 7.04199L18.7141 7.04199"
      stroke="#7D4CFF"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
