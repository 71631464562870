<!-- <template>
  <v-container>
    <v-row
      class="pa-0 ma-0"
      :style="
        $vuetify.breakpoint.mdAndDown
          ? ' margin-top: 46px !important'
          : 'margin-top: 0px !important;'
      "
    >
      <v-col md="6" cols="12" class="pb-0 pa-0">
        <p class="textPrimary--text fs-23 fw-600 f-inter">Settings</p>
      </v-col>
    </v-row>
    <v-col
      md="6"
      cols="12"
      class="pa-0"
      :class="
        $vuetify.breakpoint.lgAndUp ? 'float-right d-flex justify-end' : ''
      "
      v-if="showCreateButton"
    >
      <v-text-field
        :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 200px' : ''"
        outlined
        flat
        dense
        solo
        autocomplete="off"
        class="text-no-wrap rounded-lg mr-2 textPrimary--text fw-500 fs-12 custom-placeholder"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search team"
        hide-details
        clearable
        filled
      ></v-text-field>
      <div
        :style="
          $vuetify.breakpoint.lgAndUp
            ? 'justify-content : space-around'
            : 'padding-left: 16px; width : 98%; margin-top: 10px; justify-content: space-between'
        "
        class="approve-request rounded-lg d-flex"
      >
        <span
          class="f-inter fs-12 fw-500 dark--text d-flex align-center justify-left"
        >
          Approve team requests
        </span>
        <v-btn
          icon
          :color="auditTicketSwitch ? 'primary' : 'inActiveColor'"
          :ripple="false"
          @click="statusTeamRequest()"
        >
          <v-icon :ripple="false">{{
            auditTicketSwitch ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
          }}</v-icon>
        </v-btn>
      </div>
      <div
        :class="
          $vuetify.breakpoint.lgAndUp ? '' : 'mt-2 d-flex justify-end px-1'
        "
      >
        <v-btn
          style="letter-spacing: -0.3px"
          class="create-emp lightBackground primary--text f-inter fs-12 fw-600 mb-4 create-employee-btn"
          large
          @click="dialog = true"
          ><v-img
            style="width: 17px; height: 17px"
            class="mr-2"
            src="@/assets/dashboard/user.svg"
          ></v-img>
          Create Employee</v-btn
        >
      </div>
    </v-col>

    <v-tabs v-model="tab" hide-slider class="pa-0 ma-0">
      <v-tab class="pa-0"
        ><span class="fs-13 fw-600 text-capitalize f-inter ls-0"
          >Profile</span
        ></v-tab
      >
      <v-tab v-if="user.role == 'client'"
        ><span id="v-step-8" class="fs-13 fw-600 text-capitalize f-inter ls-0">
          Manage Team
        </span></v-tab
      >
      <v-tab v-if="user.role == 'client'"
        ><span id="v-step-16" class="fs-13 fw-600 text-capitalize f-inter ls-0"
          >Subscriptions</span
        ></v-tab
      >

    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab" style="width: inherit">
      <v-tab-item>
        <profile />
      </v-tab-item>
      <v-tab-item>
        <manage-team :search="search" />
      </v-tab-item>
      <v-tab-item>
        <SubscriptionDecider v-if="tab === 2" />
      </v-tab-item>

    </v-tabs-items>
  
    <v-dialog
      v-model="dialog"
      persistent
      height="387"
      max-width="470"
      class="emp-dialog"
    >
      <v-card>
        <v-card-title
          style="letter-spacing: -0.3px"
          class="dialogTitle--text fs-18 font-weight-medium f-inter justify-space-between"
        >
          Create Employee
          <span
            class="material-icons custom-close-icon mt-3"
            @click="closeDialog()"
          >
            close
          </span>
        </v-card-title>
        <v-divider style="border: 0.3px solid #e8ebf6"></v-divider>
        <v-form ref="newEmployee" justify="center">
          <v-row class="mt-5 pl-3 pr-3">
            <v-col
              v-for="(input, index) in employeeDialogFields"
              :key="index"
              :md="input.col_md ?? 12"
              :cols="input.cols ?? 6"
              class="pt-0 pb-0"
            >
              <v-text-field
                v-if="input.type != 'select'"
                filled
                :label="input.label"
                :placeholder="input.placeholder"
                :type="input.type"
                background-color="inputBackground"
                flat
                autocomplete="off"
                class="text-no-wrap rounded-md custom"
                :append-icon="
                  input.name == 'password' || input.name == 'confirmPassword'
                    ? input.showIcon
                    : ''
                "
                v-on:keyup.enter="createEmployee()"
                @click:append="
                  () =>
                    input.type == 'password'
                      ? ((input.type = 'text'), (input.showIcon = 'visibility'))
                      : ((input.type = 'password'),
                        (input.showIcon = 'visibility_off'))
                "
                v-model="input.model"
                :rules="
                  input.name == 'email'
                    ? emailRules
                    : input.name == 'confirmPassword'
                    ? confirmPasswordRules
                    : input.name == 'password'
                    ? passwordLengthRules
                    : requiredFieldRules
                "
              ></v-text-field>

              <v-select
                background-color="inputBackground"
                class="text-no-wrap rounded-md custom select-chip"
                filled
                v-if="input.type == 'select'"
                :items="workspaces"
                label="Select Organisation*"
                dense
                multiple
                hide-details
                clearable
                v-model="input.model"
                item-text="workspaceName"
                item-value="_id"
                :rules="requiredFieldRulesForWorkspaces"
                :menu-props="{
                  closeOnContentClick: false,
                  bottom: true,
                  offsetY: true,
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 2">
                    <span class="font-weight-medium">
                      {{ item.workspaceName }}
                    </span>
                  </v-chip>
                  <v-chip v-else-if="index === 2">
                    <span class="font-weight-medium">
                      (+{{ input.model.length - 2 }} others)
                    </span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions class="mt-5 pb-5">
          <v-btn
            block
            class="primary--text lightBackground fs-13 f-inter create-btn"
            style="text-transform: none !important"
            @click="createEmployee()"
            :loading="loader"
          >
            Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template> -->
<template>
  <v-container
    fluid
    style="margin-top: 0px !important"
    class="pt-0 pb-0 pl-0 pr-0"
  >
    <div class="d-flex flex-row" :class="$vuetify.breakpoint.xs && 'mt-9'">
      <div
        class="pr-0"
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 0 !important'
            : drawer
            ? 'width:195px;'
            : 'width:62px;'
        "
      >
        <div
          :style="
            $vuetify.breakpoint.xs
              ? 'display: block !important; margin-top: 4px !important;'
              : 'display: none !important'
          "
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="lightPurple" v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <!-- height="810" -->
        <v-navigation-drawer
          permanent
          :value="drawer"
          :mini-variant="mini"
          mini-variant-width="62px"
          width="195"
          style="top: 68px !important"
        >
          <!-- fixed -->
          <v-list nav>
            <v-list-item-group>
              <v-btn
                class="mb-3 ms-1"
                icon
                @click="
                  mini = !mini;
                  drawer = !drawer;
                "
              >
                <NavigationOpen
                  :style="drawer ? 'transform: rotate(180deg)' : ''"
                ></NavigationOpen>
              </v-btn>
              <v-list-item
                class="px-0 drawer-tile"
                :class="item.routeName === $route.name && drawer ? 'pl-1' : ''"
                :style="drawer ? 'width: 195px;' : 'width: 48px;'"
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
              >
                <v-list-item-icon
                  class="nav-item-icon-space mr-3 mt-4"
                  :style="
                    item.routeName === $route.name
                      ? 'margin-right: 16px !important'
                      : 'margin-left: 19px !important'
                  "
                >
                  <setting-icons
                    :routeName="item.routeName"
                    class="d-flex flex-row align-center"
                  />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="f-inter fw-400 fs-14"
                    :class="
                      item.routeName === $route.name
                        ? 'lightPurple--text'
                        : 'dark--text'
                    "
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>

      <div
        class="ml-4 mr-4"
        :style="
          drawer
            ? $vuetify.breakpoint.lgAndUp
              ? 'width: 86%;'
              : 'width: 100% !important'
            : 'width: 96%;'
        "
      >
        <router-view class="pl-0 pr-0"></router-view>
      </div>
    </div>
  </v-container>
</template>

<script>
import { RouterView } from "vue-router";
import NavigationOpen from "../components/common/icons/navigationDrawerOpen.vue";
import SettingIcons from "../assets/svg-icons/settings.vue";
// -----------------
// import ManageTeam from "@/components/profile_settings/ManageTeam.vue";
// import Subscriptions from "@/components/profile_settings/Subscriptions";
// import Coupons from "@/components/profile_settings/Coupons.vue";
// import profile from "@/components/profile_settings/Profile.vue";
// import FormInput from "../components/common/FormInput.vue";
// import apiService from "@/services/Api.js";
// import UrlServices from "@/services/Url";
import { mapGetters } from "vuex";
// import EventBus from "../js/EventBus";
export default {
  components: { RouterView, NavigationOpen, SettingIcons },
  data() {
    return {
      selectedItem: 0,
      drawer: true,
      mini: false,
      items: [],
      // -----------------------
      // tab: null,
      // dialog: false,
      // loader: false,
      // auditTicketSwitch: false,
      // search: "",
      // isRequestApproved: "Active",
      // showCreateButton: false,
      // employeeDialogFields: [
      //   {
      //     name: "fullName",
      //     label: "Full Name*",
      //     placeholder: "Enter Full Name",
      //     type: "text",
      //     cols: 12,
      //     col_md: 12,
      //     model: "",
      //   },
      //   {
      //     name: "email",
      //     label: "Email*",
      //     placeholder: "Enter Your Email Here",
      //     type: "email",
      //     cols: 12,
      //     col_md: 12,
      //     model: "",
      //   },
      //   {
      //     name: "password",
      //     label: "Password*",
      //     placeholder: "Enter Password",
      //     type: "password",
      //     cols: 12,
      //     col_md: 6,
      //     showIcon: "visibility_off",
      //     model: "",
      //   },
      //   {
      //     name: "confirmPassword",
      //     label: "Confirm Password*",
      //     placeholder: "Enter Password",
      //     type: "password",
      //     cols: 12,
      //     col_md: 6,
      //     showIcon: "visibility_off",
      //     model: "",
      //   },
      //   {
      //     name: "organisations",
      //     label: "Select Organisation*",
      //     placeholder: "Select Organisation",
      //     type: "select",
      //     cols: 12,
      //     col_md: 12,
      //     model: [],
      //   },
      // ],
      // requiredFieldRulesForWorkspaces: [
      //   (v) => v.length > 0 || "At least one organisation is required",
      // ],
      // emailRules: [
      //   (v) => !!v || "Field is required",
      //   (v) =>
      //     /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
      //     "E-mail must be valid",
      // ],
      // passwordLengthRules: [
      //   (v) => !!v || "Field is required",
      //   (v) =>
      //     (v && v.length >= 6) ||
      //     "Length must be greater than or equal to 6 digits",
      //   (v) =>
      //     (v && v.length <= 15) ||
      //     "Length must be less than or equal to 15 digits",
      // ],
      // confirmPasswordRules: [
      //   (v) => !!v || "Field is required",
      //   (v) =>
      //     v === this.employeeDialogFields[3].model || "Password does not match",
      //   (v) =>
      //     (v && v.length >= 6) ||
      //     "Length must be greater than or equal to 6 digits",
      //   (v) =>
      //     (v && v.length <= 15) ||
      //     "Length must be less than or equal to 15 digits",
      // ],
      // requiredFieldRules: [(v) => !!v || "Field is required"],
    };
  },
  watch: {
    // tab() {
    //   this.tab == 1
    //     ? (this.showCreateButton = true)
    //     : (this.showCreateButton = false);
    // },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      paginate: "ticket/getPaginate",
      workspaces: "auth/getUserWorkSpaces",
      currentWorkspace: "auth/getCurrentWorkspace",
    }),

    sideBar() {
      let user = this.user;
      console.log(user, "user");
      let items = [
        {
          title: "Profile",
          to: "/settings",
          pathName: "/settings",
          routeName: "Profile",
          auth: false,
        },
        {
          ...(!user.isEmployee
            ? {
                title: "Organization",
                to: "/settings/organization",
                pathName: "/settings/organization",
                routeName: "Organization",
                auth: false,
              }
            : {}),
        },
        {
          ...(!user.isEmployee
            ? {
                title: "Manage Team",
                to: "/settings/manage-team",
                pathName: "/settings/manage-team",
                routeName: "ManageTeam",
                auth: false,
              }
            : {}),
        },
        {
          ...(!user.isEmployee
            ? {
                title: "Subscription",
                to: "/settings/subscription",
                pathName: "/settings/subscription",
                routeName: "Subscription",
                auth: false,
              }
            : {}),
        },
      ];
      return items;
    },
  },
  mounted() {
    this.items = this.sideBar;
    // this.$router.push("/organization");
    // this.$router.push("/profile").catch(() => {});
    // this.auditTicketSwitch = this.currentWorkspace.auditTicket;
    // this.stripePaymentVerificationQuery();
  },

  methods: {
    // Method to close create employee dialog and resetting fields
    // approveRequest(emp) {
    //   this.$store
    //     .dispatch("user/changeStatus", {
    //       id: emp._id,
    //       status:
    //         emp.status == "Active"
    //           ? "InActive"
    //           : emp.status == "InActive"
    //           ? "Active"
    //           : "",
    //     })
    //     .then(() => {
    //       this.$showNotificationBar("success", "User updated successfully");
    //     });
    // },
    // closeDialog() {
    //   this.$refs.newEmployee.reset();
    //   // this.dialog = false;
    // },
    // Method to create new employee
    // createEmployee() {
    //   if (!this.$refs.newEmployee.validate()) {
    //     return this.$showNotificationBar(
    //       "error",
    //       "Please fill all the required fields"
    //     );
    //   }
    //   if (
    //     this.employeeDialogFields[2].model != this.employeeDialogFields[3].model
    //   ) {
    //     return this.$showNotificationBar(
    //       "error",
    //       "The password confirmation does not match"
    //     );
    //   }

    //   this.loader = true;
    //   this.$store
    //     .dispatch("user/createEmployee", {
    //       name: this.employeeDialogFields[0].model,
    //       email: this.employeeDialogFields[1].model,
    //       password: this.employeeDialogFields[2].model,
    //       workspaces: this.employeeDialogFields[4].model,
    //       role: "employee",
    //     })
    //     .then((res) => {
    //       this.loader = false;
    //       this.dialog = false;
    //       this.$refs.newEmployee.reset();
    //       this.$showNotificationBar("success", "Employee created successfully");
    //       EventBus.$emit("get-employees");
    //       this.$store.commit("ticket/setPaginate", {
    //         itemsPerPage: 10,
    //         page: 1,
    //         lastPage:
    //           this.paginate.total + 1 < 10
    //             ? 1
    //             : Math.ceil((this.paginate.total + 1) / 10),
    //         total: this.paginate.total + 1,
    //       });
    //     })
    //     .catch((err) => {
    //       this.$showNotificationBar("error", err.response.data.message);
    //       console.log(err.response, "create emp err");
    //       this.loader = false;
    //       // this.dialog = false;
    //     });
    // },

    /*
     ** Handle Stripe Verifications from query status
     */
    stripePaymentVerificationQuery() {
      const paymentStatus = this.$route.query?.success;
      /*
       ** Validate if payment status check exists
       */
      if (!paymentStatus) return;
      /*
       ** Show snackbar for case of success and failed
       */
      if (paymentStatus == "true") {
        this.$showNotificationBar("success", "Payment has been dispatched");
      } else if (paymentStatus == "false") {
        this.$showNotificationBar(
          "error",
          "Kindly try again , as plan is not updated"
        );
      }
      /*
       ** Clear query parameters in order for them to get data
       */
      this.$router.replace("/settings");
    },
  },
};
</script>

<style scoped>
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.drawer-tile {
  height: 48px;
  width: 48px;
}
.nav-item-icon-space {
  align-self: center;
}

/* -------------------- */

/* .v-application .mt-15 {
  margin-top: 16px !important;
} */
/* .v-btn:not(.v-btn--round).v-size--default {
  height: 45px !important;
} */
/* .create-emp {
  border-radius: 8px !important;
  text-transform: none !important;
} */
/* .emp-dialog {
  background-color: #fff !important;
  border-radius: 10px !important;
  min-height: 387px !important;
} */
/* :deep .v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
} */
/* .create-btn {
  width: 74px !important;
  height: 38px !important;
  background: #f4f1fe;
  border-radius: 8px;
  font-weight: 600 !important;
  letter-spacing: -0.3px;
} */
/* :deep .v-text-field__details {
  white-space: break-spaces !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  border-radius: 10px !important;
}
:deep .select-chip .v-chip {
  background: #f4f1fe !important;
  color: #6634e9 !important;
  padding: 10px !important;
  border-radius: 14px !important;
}
:deep .search-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
  border-bottom: 1px solid black;
  height: 35px !important;
}
.create-employee-btn {
  height: 39px !important;
}
.approve-request {
  width: 200px;
  height: 39px;
  border: 0.6px solid rgba(232, 235, 244, 1);
  border-radius: 5px;
  margin-right: 9px;
}
:deep .v-input--selection-controls {
  margin-top: 0px !important;
}
:deep .v-input__icon {
  width: 14px !important;
  min-width: 14px !important;
}
:deep .v-icon {
  font-size: 14px;
  color: black;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(232, 235, 244, 1);
}
:deep .custom-placeholder input::placeholder {
  color: black !important;
}

.v-input__slot {
  min-height: 37px !important;
}
.ellipsis-text {
  margin: 0px;
  white-space: nowrap !important;
  width: 70px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */
</style>
